<template>
    <div class="input-container" :style="containerStyle">
        <div v-if="title" class="form-label">
            <span v-if="required" class="required" v-text="'*'" />
            <span v-text="title" />
            <div v-if="tips" class="info-icon" :tooltip="tips" tooltip-touch />
        </div>
        <input
            :name="name"
            :required="required"
            :disabled="true"
            :value="modelValue"
            class="hide"
            :isComposing="false"
        />
        <div class="select-container">
            <div
                class="textbox-container"
                ref="textboxContainer"
                :data-error-msg="invalidMsg"
            >
                <!-- 改為用onFocus(@focus)事件觸發顯示下拉選單 -->
                <input
                    ref="selectInput"
                    type="text"
                    class="select target"
                    :name="name"
                    autocomplete="off"
                    tabindex="0"
                    :readonly="!searchable && this.filteredOption.length"
                    :class="dropdownStyleClass"
                    :placeholder="placeholder"
                    :required="required"
                    :disabled="disabled"
                    v-model="searchValue"
                    @input="isShowList = true"
                    @focus="focusInput"
                    @keydown="
                        (event) => {
                            if (event.keyCode !== 9 && !searchable) {
                                event.preventDefault()
                            }
                        }
                    "
                    @keydown.esc="isShowList = false"
                    @keydown.tab="isShowList = false"
                    @keydown.enter="(event) => setHoveredToValue(event)"
                    @keydown.arrow-up="hoverOptionByKeyboard('up')"
                    @keydown.arrow-down="hoverOptionByKeyboard('down')"
                />
                <div class="arrow" />
                <div
                    v-if="!disabled && searchable && isShowList && searchValue"
                    class="x-cancel-icon"
                    @click="deleteInputValue"
                />
            </div>
            <div
                ref="optionList"
                class="option-container"
                :class="mode === 'checkbox' && 'options-checkbox-container'"
                v-if="!disabled && isShowList"
            >
                <template v-if="mode === 'select'">
                    <div
                        v-for="(option, index) in reducedFilterOption"
                        :key="index"
                        :data-value="option.value"
                        :class="[
                            {
                                disabled:
                                    option?.isDisabled || option?.isDivider,
                                hover: index === hoveredIndex,
                                divider: option?.isDivider
                            },
                            option.className ?? ''
                        ]"
                        @mouseenter="hoveredIndex = index"
                        @click="changeHandler(option)"
                        class="option"
                    >
                        <div
                            :class="{ 'option-divider': option?.isDivider }"
                            v-text="option.label"
                        />
                    </div>
                </template>
                <template v-if="mode === 'checkbox'">
                    <Checkbox
                        v-for="(option, key) in filteredOption"
                        :key="key"
                        :name="option.label"
                        :label="option.label"
                        :value="option.value"
                        v-model="checkboxValue"
                        class="option"
                        :class="{ hover: key === hoveredIndex }"
                        @mouseenter="hoveredIndex = key"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { dropdownGaSetting } from '@/assets/javascripts/commonGaSetting'
import Checkbox from './Checkbox.vue'
import _ from 'lodash'

export default {
    name: 'Dropdown',
    emits: ['update:modelValue'],
    components: {
        Checkbox
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '請選擇...'
        },
        modelValue: {
            type: [String, Number, Boolean, Array],
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        name: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        searchable: {
            type: Boolean,
            default: false
        },
        isError: {
            type: Boolean,
            default: false
        },
        errorMessage: {
            type: String
        },
        width: {},
        options: {
            type: Array,
            default: function () {
                return []
            }
        },
        max: {},
        min: {},
        tips: {
            type: String,
            default: ''
        },
        inputErrorMsg: {
            type: String,
            default: ''
        },
        mode: {
            type: String,
            default: 'select'
        },
        isCheckboxNum: {
            type: Boolean,
            default: false
        },
        //可進行全選，如客戶服務頁面
        hasSelectedALL: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        changeHandler: function (option) {
            if (option?.isDivider || option?.isDisabled) {
                return
            } else {
                this.selectValue = option.value
                this.searchValue = option.label
                this.$nextTick(() => {
                    this.isShowList = false
                })
            }
        },
        setInputGa: function () {
            if (this.title) {
                if (dropdownGaSetting.includes(this.name)) return
                else
                    this.$setGaEvent(
                        `${this.name}${this.required ? 'Required' : ''}Filter`,
                        'click-Filter'
                    )
            }
        },
        clickBlankHandler: function (event) {
            if (!this.$el.contains(event.target)) {
                this.isShowList = false
            }
        },
        focusInput: function () {
            if (this.disabled) return
            this.isShowAllOptions = true
            this.isShowList = true
            this.setInputGa()
            this.setRenderIndex()
        },
        setRenderIndex: function () {
            this.$nextTick(() => {
                const list = this.$refs.optionList
                list.addEventListener('scroll', () => {
                    const lastOptions = list.querySelectorAll('.option')
                    if (lastOptions.length > 0)
                        if (
                            list.scrollTop + list.offsetHeight >=
                                lastOptions[lastOptions.length - 1].offsetTop &&
                            this.filterSectionCount > this.renderIndex
                        ) {
                            this.renderIndex += 1
                        }
                })
            })
        },
        deleteInputValue: function () {
            this.selectValue = ''
            this.searchValue = ''
            this.$setGaEvent('textboxDropdownDelete', 'click-Icon')
        },
        hoverOptionByKeyboard: function (direct) {
            this.isShowList = true
            if (direct === 'down') {
                if (this.hoveredIndex < this.filteredOption.length - 1) {
                    this.hoveredIndex++
                    if (this.filteredOption[this.hoveredIndex].isDivider)
                        this.hoveredIndex++
                }
            } else {
                if (this.hoveredIndex > 0) {
                    this.hoveredIndex--
                    if (this.filteredOption[this.hoveredIndex].isDivider)
                        this.hoveredIndex--
                }
            }
            this.scrollToOption()
            event.preventDefault()
        },
        scrollToOption: function () {
            this.$nextTick(() => {
                const list = this.$refs.optionList
                const options = list.querySelectorAll('.option')
                if (!options) return
                const hoveredOption = options[this.hoveredIndex]
                if (!hoveredOption) return
                hoveredOption.scrollIntoView(false)
            })
        },
        setHoveredToValue: function () {
            if (this.isComposing) {
                this.isComposing = false
                return
            }
            const option = this.filteredOption[this.hoveredIndex]
            if (!option) return
            this.changeHandler(option)
        },
        compositionStart: function () {
            this.isComposing = true
        },
        showCheckText: function (opt = {}) {
            let text = ''
            const isAllCheck = _.every(opt, (state) => state)
            if (isAllCheck) {
                text = '全部'
            } else {
                const filterOptions = {}
                _.forEach(this.filteredOption, (data) => {
                    filterOptions[data.value] = data.label
                })
                const checkOptionText = []
                _.keys(opt).forEach((key) => {
                    if (opt[key]) checkOptionText.push(filterOptions[key])
                })
                text = _.join(checkOptionText, ',')
            }
            this.searchValue = text
        },
        getCheckboxArr: function (obj) {
            const result = []
            _.keys(obj).forEach((key) => {
                if (obj[key])
                    result.push(this.isCheckboxNum ? parseInt(key) : key)
            })
            return result
        },
        getCheckboxObj: function (arr) {
            const result = {}
            _.forEach(arr, (val) => {
                result[val] = true
            })
            return result
        }
    },
    computed: {
        selectValue: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        },
        checkboxValue: {
            get() {
                let optionState = {}
                _.forEach(this.filteredOption, (opt) => {
                    optionState[opt.value] = false
                })
                _.forEach(this.modelValue, (val) => {
                    optionState[val] = true
                })
                return optionState
            },
            set(val) {
                this.$emit('update:modelValue', this.getCheckboxArr(val))
            }
        },
        selectedOption: function () {
            //代表頁面有開放選擇全部功能
            if (this.hasSelectedALL) {
                //選擇全部的情形
                if (this.options[0].isSelectedAll) {
                    return this.options[0]
                } else {
                    return this.options.find((option) => {
                        return +option.value === this.selectValue[0]
                    })
                }
            } else {
                return this.options.find((option) => {
                    return option.value === this.selectValue
                })
            }
        },
        dropdownStyleClass: function () {
            const className = {}
            if (this.isError) className['error'] = true
            if (this.disabled) className['disabled'] = true
            if (this.modelValue === '') className['placeholder'] = true
            return className
        },
        containerStyle: function () {
            const style = {}
            if (typeof this.width === 'string' && this.width.includes('%')) {
                style['width'] = this.width
            } else if (typeof this.width === 'number') {
                style['width'] = `${this.width}px`
            }
            return style
        },
        filteredOption: function () {
            if (this.searchable && !this.isShowAllOptions) {
                return this.options.filter((item) => {
                    return Object.freeze(item.label.includes(this.searchValue))
                })
            } else {
                return this.options
            }
        },
        reducedFilterOption: function () {
            const filteredOption = this.filteredOption
            const hasSlice = filteredOption.length > 100
            let sliceOption = []
            if (hasSlice)
                sliceOption = filteredOption.slice(0, 100 * this.renderIndex)
            return hasSlice ? sliceOption : this.filteredOption
        },
        filterSectionCount: function () {
            return Math.floor(this.filteredOption.length / 100)
        },
        invalidMsg: function () {
            return this.inputErrorMsg || `請選擇${this.title}`
        }
    },
    watch: {
        selectedOption: {
            handler() {
                this.$nextTick(() => {
                    if (this.mode === 'checkbox') return
                    this.searchValue = this.selectedOption?.label ?? ''

                    // hover選取到的選項
                    const index = this.filteredOption.indexOf(
                        this.selectedOption
                    )
                    this.hoveredIndex = index
                })
            },
            immediate: true
        },
        filteredOption: function () {
            this.hoveredIndex = 0
        },
        isShowList: function (newVal) {
            if (this.isShowList || this.mode === 'checkbox') return
            if (this.searchValue !== this.selectedOption?.label) {
                this.searchValue = ''
            }
            if (!newVal) {
                this.renderIndex = 1
            }
        },
        searchValue: function () {
            this.isShowAllOptions = false
            if (this.searchValue === '') this.selectValue = ''
        },
        modelValue: function () {
            if (this.mode === 'checkbox') {
                let checkOptions = this.getCheckboxObj(this.modelValue)
                this.showCheckText({ ...this.checkboxValue, ...checkOptions })
            }
            if (this.modelValue) {
                this.$refs.textboxContainer?.classList.remove(
                    'input-error-ci-style'
                )
            }
        }
    },
    data() {
        return {
            searchValue: '',
            isShowList: false,
            hoveredIndex: 0,
            isShowAllOptions: false,
            renderIndex: 1
        }
    },
    mounted() {
        document.addEventListener('click', this.clickBlankHandler)
        document.addEventListener('compositionstart', this.compositionStart)
    },
    unmounted() {
        document.removeEventListener('click', this.clickBlankHandler)
        document.removeEventListener('compositionstart', this.compositionStart)
    }
}
</script>

<style lang="scss" scoped>
.info-icon {
    margin-left: 5px;
    width: 14px;
    height: 14px;
}
.input-container {
    width: 210px;
    margin-bottom: 28px;
    position: relative;

    &:last-child {
        margin-right: 0px;
    }
}

.placeholder {
    color: $placeholder-black;
}

.select-container {
    position: relative;
}

.x-cancel-icon {
    position: absolute;
    top: 14px;
    right: 4%;
    background-color: $primary-grey;
    cursor: pointer;
    width: 12px;
    height: 12px;
}

.textbox-container {
    overflow-x: hidden;
}

.select {
    background-color: $primary-grey;
    border: 1px solid $primary-grey;
    box-sizing: border-box;
    border-radius: 3px;
    outline: none;
    padding: 10px 25px 10px 10px;
    font-size: 14px;
    line-height: 16px;
    color: $seventh-black;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    /*
        prevent iOS scale
        enlarge by 16/14 = 114.285714286%
        scale down by 14/16 = 0.875%
            height 40/0.875 = 45.7142857143
    */
    font-size: 16px;
    height: 45.7142857143px;
    width: 114.285714286%;
    transform: scale(0.8753);
    transform-origin: left top;

    &:focus {
        border: 1px solid $third-blue;
    }

    &.error {
        border: 1px solid $primary-red !important;
    }
}

.option-container {
    display: block;
    position: absolute;
    background-color: $primary-white;
    border: 1px solid $primary-grey;
    border-radius: 0 0 3px 3px;
    max-height: 140px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;
    margin-top: -6px;
}

.option {
    display: flex;
    align-items: center;
    vertical-align: middle;
    box-sizing: border-box;
    width: 100%;
    min-height: 40px;
    padding: 10px 10px;
    color: $seventh-black;
    font-size: 14px;
    line-height: 16px;
    text-overflow: ellipsis;
    cursor: pointer;
    pointer-events: inherit;
    position: relative;

    &.hover {
        background: $primary-grey;
    }
    &.disabled {
        opacity: 0.4;
        &:hover {
            background: $primary-white;
        }
    }
    &.divider {
        opacity: 1;
    }
}

.form-label {
    color: $sixth-black;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    .required {
        color: $primary-red;
    }
}

.error-message {
    position: absolute;
    font-size: 12px;
    color: $fourth-red;
    margin-top: 5px;
}

.option-divider {
    padding-inline: 5px;
    background-color: $primary-white;
    justify-self: center;
    margin: 0 auto;
    color: $placeholder-black;
    &::after {
        content: '';
        width: calc(100% - 20px);
        height: 1px;
        left: 10px;
        top: 50%;
        background-color: $placeholder-black;
        position: absolute;
        z-index: -1;
    }
}

:deep(.checkbox-container) {
    position: relative;

    label {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding-left: 36px;
        width: calc(100% - 36px);
        height: 100%;
    }
}

// 目前沒查到原因，先用CSS將小人頭隱藏不讓使用者按到
input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    position: absolute;
    right: 0;
}
</style>
